.hero-movie {
    position: relative;
    min-height: 100vh;
    background-color: var(--pri-bg-color);
    overflow-y: none;
}

.hero-movie>* {
    z-index: 0;
}

.hero-movie .img-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    min-width: 50%;
    background-color: black;
    opacity: 0.7;
    min-height: 100%;
    overflow-y: none;
}

