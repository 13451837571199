:root {
    --pri-font-color: #000;
    --pri-font-color-light: #fff;
    --sec-font-color: #333333;
    --pri-bg-color: #0e153a;
    --pri-bg-color-dark: #808080;
    --pri-bg-color-light: #ffffff;
    --sec-bg-color: #e2f3f5;
    --sec-bg-color-dark: #9eaaac;
    --sec-bg-color-light: #ebf7f8;
    --pri-accent-color: #3d5af1;
    --pri-accent-color-light: #565b75;
    --pri-accent-color-dark: #0a0f29;

    --border-radius: 10px;

    --transition: all 0.3s linear;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@media screen and (min-width: 600px) {
    .App {
        max-width: 1200px;
    }
}

.App {
    position: relative;
    background-color: var(pri-bg-color);
    color: #fff;
    max-width: 100vw;
    overflow: hidden;
    margin: 20px auto;
}

img {
    width: 100%;
}

:root {
    --pri-font-color: #000;
    --pri-font-color-light: #fff;
    --sec-font-color: #333333;
    --pri-bg-color: #0e153a;
    --pri-bg-color-dark: #808080;
    --pri-bg-color-light: #ffffff;
    --sec-bg-color: #e2f3f5;
    --sec-bg-color-dark: #9eaaac;
    --sec-bg-color-light: #ebf7f8;
    --pri-accent-color: #3d5af1;
    --pri-accent-color-light: #565b75;
    --pri-accent-color-dark: #0a0f29;

    --border-radius: 10px;

    --transition: all 0.3s linear;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@media screen and (min-width: 600px) {
    .App {
        max-width: 1200px;
    }
}

.App {
    position: relative;
    background-color: var(pri-bg-color);
    color: #fff;
    max-width: 100vw;
    max-height: 100vh;
    overflow: none;
    margin: 20px auto;
}

img {
    width: 100%;
    height: 100%;
}

button {
    outline: none;
    cursor: pointer;
}

svg {
    cursor: pointer;
    height: 100%;
}

button {
    outline: none;
    cursor: pointer;
}

.bottom::after {
    content: '';
    height: 100%;
    width: 100vw;
    background-color: var(--pri-bg-color);
}

.accordion-items {
    max-width: 95vw;
    background: linear-gradient(to bottom right, #fff, #f7f7f7);
    background: transparent;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0 10px 15px -20px rgba(0, 0, 0, 0.3),
        0 30px 45px -30px rgba(0, 0, 0, 0.3), 0 80px 55px -30px rgba(0, 0, 0, 0.1);
}

.accordion-heading {
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #e7e7e7;
    letter-spacing: 0.8px;
    padding: 15px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
}

.accordion-heading:nth-last-child(2) {
    border-bottom: 0;
}

.accordion-heading:hover {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0;
}

.accordion-heading:first-child:hover {
    border-radius: 3px 3px 0 0;
}

.accordion-heading:nth-last-child(2):hover {
    border-radius: 0 0 3px 3px;
}

.toggleIcon {
    vertical-align: middle;
    display: inline-block;
    border-top: 7px solid #f5f5f5;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    transform: rotate(0);
    transition: all 0.5s;
    margin-top: 5px;
}

.showIcon {
    transform: rotate(-180deg);
}

.notActive {
    transform: rotate(0deg);
}

.accordion-content {
    background: transparent;
    padding: 15px;
    font-size: 13px;
    line-height: 1.5;
}

.accordion {
    position: relative;
    left: 0;
    right: 0;
    top: -100vh;
}

.movielist-container{
    overflow-y: "scroll";
    margin-top: 3rem;
    max-height: 70vh
}