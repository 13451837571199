.modal {
    display: block;
    position: fixed;
    z-index: 6;
    left: 0;
    top: 0;
    width: 100%;
    height: 80vh;
    overflow: none;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    margin: 15vh auto;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 5px;
    border: 1px solid #888;
    width: 80%;
    height: 50vh;
  }
  
  .close {
    color: var(--pri-accent-color);
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

.trailer{
    width: 100%;
    height: 100%
}