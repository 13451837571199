header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: var(--sec-bg-color);
}

.title {
  text-align: left;
  margin: 0 2rem;
  padding: -2.5rem 0.5rem;
  color: var(--pri-accent-color);
  font-size: 0.5rem;
  max-width: 20%;
}

.title>span {
  color: var(--pri-accent-color-light);
  font-size: 1rem;
  padding: -2.5rem 0.5rem;
}


header>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  position: relative;
}

.input {
  padding: 10px;
  width: 40px;
  max-height: 40px;
  background: none;
  border: 4px solid var(--pri-accent-color);
  border-radius: 50px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 20px;
  color: var(--pri-accent-color);
  outline: none;
  transition: .5s;
}

.box:hover input {
  width: 240px;
  background: var(--sec-bg-color);
  border-radius: 10px;
}

.box svg {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: var(--pri-accent-color);
  transition: .2s;
}

.box:hover svg {
  opacity: 0;
  z-index: -1;
}

.headline {
  color: var(--pri-accent-color);
  display: inline-block;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 3rem;
  background: var(--sec-bg-color);
}

@media screen and (min-width: 600px) {
  .search-bar {
    min-width: 10rem;
  }

  .search {
    font-size: 15px;
    margin-right: 0.75rem;
  }

  .title h1 {
    font-size: 1.5rem;
    margin-bottom: 0.2rem
  }
}